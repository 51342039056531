export default [
  // 单品套餐&同步兑换码
  {
    path: '/Sync',
    name: 'YonghuiSync',
    meta: { title: '提卡码' },
    component: () => import('@/views/YonghuiSync')
  },
  {
    path: '/SyncList',
    name: 'SyncList',
    meta: { title: '提卡码' },
    component: () => import('@/views/YonghuiSync/list')
  },
  {
    path: '/SyncCrowded',
    name: 'SyncCrowded',
    meta: { title: '提卡码' },
    component: () => import('@/views/YonghuiSync/crowded')
  },
  {
    path: '/SyncOutStock',
    name: 'SyncOutStock',
    meta: { title: '提卡码' },
    component: () => import('@/views/YonghuiSync/outStock')
  }
]
