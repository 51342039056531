export default [
  // 同步链接兑换码
  {
    path: '/sl/:code',
    name: 'SyncLink',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/SyncLink')
  },
  {
    path: '/SyncLinkList',
    name: 'SyncLinkList',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/SyncLink/list')
  },
  {
    path: '/SyncLinkCrowded',
    name: 'SyncLinkCrowded',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/SyncLink/crowded')
  },
  {
    path: '/SyncLinkOutStock',
    name: 'SyncLinkOutStock',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/SyncLink/outStock')
  }
]
