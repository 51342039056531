export default [
  // 同步兑换链接（弹框版）
  {
    path: '/syncPop',
    name: 'syncPop',
    meta: { title: '兑换码核销页面' },
    component: () => import('@/views/syncPop')
  },
  {
    path: '/syncPopList',
    name: 'syncPopList',
    meta: { title: '兑换码核销页面' },
    component: () => import('@/views/syncPop/list')
  },
  {
    path: '/syncPopCrowded',
    name: 'syncPopCrowded',
    meta: { title: '兑换码核销页面' },
    component: () => import('@/views/syncPop/crowded')
  },
  {
    path: '/syncPopOutStock',
    name: 'syncPopOutStock',
    meta: { title: '兑换码核销页面' },
    component: () => import('@/views/syncPop/outStock')
  }
]
