import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import 'lib-flexible'
import '@/assets/style/base.less'
import common from '@/utils/common.js'
import {
  Toast,
  ActionSheet,
  Pagination,
  Form,
  Field,
  Dialog,
  CountDown,
  Button,
  Popup,
  Cascader
} from 'vant'
import ScratchCard from 'lzy-scratch-card'
import VueClipboard from 'vue-clipboard2'
import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.use(VueClipboard)
Vue.use(ScratchCard)
Vue.use(Toast)
Vue.use(ActionSheet)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(Field)
Vue.use(Dialog)
Vue.use(CountDown)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Cascader)

Vue.config.productionTip = false
Vue.prototype.common = common
// 拦截器
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '兑换页'
  const search = window.location.search

  // 需求ID1002683，要求把域名(https://ydlife.ltd/?R=8)剪短拦截跳转
  if (search === '?R=8') {
    window.location.href = `${process.env.VUE_APP_ORIGIN_HREF}/#/Recharge?type=8`
    return true
  }
  next()
})

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
