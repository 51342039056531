export default [
  // 实物兑换码
  {
    path: '/physical',
    name: 'PhysicalRedeemCode',
    meta: { title: '实物兑换码' },
    component: () => import('@/views/PhysicalRedeemCode')
  },
  {
    path: '/physicalLogisticsEnquiry',
    name: 'PhysicalLogisticsEnquiry',
    meta: { title: '物流查询' },
    component: () => import('@/views/PhysicalRedeemCode/logisticsEnquiry')
  }
]
