// 目前同步兑换链接只有电商样式，需要新增一个通用版本样式供其他非电商项目使用
export default [
  {
    path: '/OtherSync',
    name: 'OtherSync',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/OtherSync')
  },
  {
    path: '/OtherSyncList',
    name: 'OtherSyncList',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/OtherSync/list')
  },
  {
    path: '/OtherSyncCrowded',
    name: 'OtherSyncCrowded',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/OtherSync/crowded')
  },
  {
    path: '/OtherSyncOutStock',
    name: 'OtherSyncOutStock',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/OtherSync/outStock')
  }
]
