export default [
  // 特殊兑换码需求（（1）发放手机号必须与兑换手机号一致（2）同步兑换码（3）需要专属兑换链接，在其他兑换链接不可被兑换）
  {
    path: '/Special',
    name: 'Special',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/Special')
  },
  {
    path: '/SpecialList',
    name: 'SpecialList',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/Special/list')
  },
  {
    path: '/SpecialCrowded',
    name: 'SpecialCrowded',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/Special/crowded')
  },
  {
    path: '/SpecialOutStock',
    name: 'SpecialOutStock',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/Special/outStock')
  }
]
