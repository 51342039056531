export default [
  // 套餐兑换码类型-套餐涂层短链
  {
    path: '/combo/:code',
    name: 'comboSync',
    meta: { title: '套餐涂层短链' },
    component: () => import('@/views/comboSync')
  },
  {
    path: '/comboDetail',
    name: 'comboSyncDetail',
    meta: { title: '套餐涂层短链券码详情' },
    component: () => import('@/views/comboSync/orderDetail')
  }
]
