export default [
  // 套餐兑换码
  {
    path: '/shipMeal',
    name: 'ShipMeal',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/ShipMeal')
  },
  {
    path: '/shipMealList',
    name: 'ShipMealList',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/ShipMeal/list')
  },
  {
    path: '/shipMealCrowded',
    name: 'ShipMealCrowded',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/ShipMeal/crowded')
  },
  {
    path: '/shipMealOutStock',
    name: 'ShipMealOutStock',
    meta: { title: '通用定制兑换' },
    component: () => import('@/views/ShipMeal/outStock')
  }
]
